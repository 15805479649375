<template>
  <div class="page">
    <Navbar title="用户登录" />
    <img
      class="login-top"
      :src="require('../../../assets/images/seller/login-top.jpg')"
    />
    <van-form @submit="login" class="form">
      <van-cell-group>
        <van-field
          v-model="phone"
          label="手机号"
          type="tel"
          placeholder="请输入手机号"
          :rules="[{ required: true, message: '请填写手机号码' }]"
        ></van-field>
        <van-field
          v-model="password"
          center
          clearable
          label="登录密码"
          placeholder="请输入登录密码"
          :rules="[{ required: true, message: '请输入登录密码' }]"
        >
        </van-field>
        <van-row>
          <van-col offset="8" span="8" class="subbtn">
            <van-button
              block
              type="primary"
              :color="COLOR_S1"
              icon="success"
              size="small"
              native-type="submit"
            >
              立即登录
            </van-button></van-col
          >
        </van-row>
      </van-cell-group>
    </van-form>
    <van-row>
      <van-col span="24"
        >还没有平台账号？<a class="color-m" @click="register"
          >点击此处立即注册</a
        ></van-col
      >
    </van-row>
    <van-popup :show="loadingShow" style="background-color: transparent"
      ><van-loading size="24px" :color="COLOR" vertical>加载中...</van-loading>
    </van-popup>
    <LogVisit ref="logVisit" module-type="OTH" module="OTH_LOGIN" />
  </div>
</template>
<script>
import { Form, Field, CellGroup } from 'vant'
import Navbar from '../../module/common/Navbar.vue'
import LogVisit from '../../module/common/LogVisit.vue'
export default {
  components: {
    Navbar: Navbar,
    LogVisit: LogVisit,
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup
  },
  data () {
    return {
      platform: '',
      app: '',
      code: '',
      sendBtnTime: 0,
      loadingShow: false,
      returnBack: 'N',
      validateState: true,
      upper: '1',
      phone: '',
      password: ''
    }
  },
  mounted () {
    var query = this.$route.query
    this.platform = query.platform
    this.app = query.app
    window.sessionStorage.setItem(this.SESSION_APP, this.app)
    window.sessionStorage.setItem(this.SESSION_PLATFORM, this.platform)
    var uc = window.sessionStorage.getItem(this.SESSION_SHARER)
    if (uc !== null && uc !== undefined && uc !== '') {
      this.upper = uc
    }
    if (this.platform === 'WAP') {
      this.code = query.code
      this.autoLogin()
    }
    this.$refs.logVisit.createLog()
  },
  methods: {
    async autoLogin () {
      this.loadingShow = true
      var pd = { platform: this.platform, app: this.app, code: this.code }
      const { data: res } = await this.$http.post(this.UMS_URL + '/customer/user/loginBind', this.$qs.stringify(pd))
      if (res.status === '200') {
        window.sessionStorage.setItem(this.SESSION_SERIAL, res.data.openid)
        if (res.data.userCode !== '0') {
          window.sessionStorage.setItem(this.SESSION_TOKEN, res.data.token)
          window.sessionStorage.setItem(this.SESSION_USER, res.data.userCode)
          this.retrieveUser()
        }
      }
      this.loadingShow = false
    },
    async login () {
      this.loadingShow = true
      var pd = { phone: this.phone, password: this.password }
      const { data: res } = await this.$http.post(this.UMS_URL + '/customer/user/login', this.$qs.stringify(pd))
      this.loadingShow = false
      if (res.status === '200') {
        window.sessionStorage.setItem(this.SESSION_TOKEN, res.data.token)
        window.sessionStorage.setItem(this.SESSION_USER, res.data.code)
        this.retrieveUser()
        this.platformBind()
      } else {
        this.$dialog.alert({
          title: '用户登录',
          message: res.msg
        }).then(() => {
          // on close
        })
      }
    },
    async platformBind () {
      var serial = window.sessionStorage.getItem(this.SESSION_SERIAL)
      var user = window.sessionStorage.getItem(this.SESSION_USER)
      var pd = {
        serialCode: serial,
        platform: this.platform,
        app: this.app,
        userCode: user
      }
      const { data: res } = await this.$http.post(this.UMS_URL + '/customer/user/platformBind', this.$qs.stringify(pd))
      if (res.status === '200') {
      }
    },
    async retrieveSeller () {
      var pd = { code: this.user.sellerCode }
      const { data: res } = await this.$http.post(this.UMS_URL + '/sell/seller/retrieveSeller', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.seller = res.data
        window.sessionStorage.setItem(this.SESSION_OPERATOR, res.data.operatorCode)
        window.sessionStorage.setItem(this.SESSION_SELLER_TYPE, res.data.sellerType)
        window.sessionStorage.setItem(this.SESSION_SELLER_MODEL, res.data.model)
        console.log(res.data)
        this.$router.push({ path: '/seller/home' })
      }
    },
    async retrieveUser () {
      var user = window.sessionStorage.getItem(this.SESSION_USER)
      var pd = { userCode: user }
      const { data: res } = await this.$http.post(this.UMS_URL + '/sell/seller/retrieveUser', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.user = res.data
        window.sessionStorage.setItem(this.SESSION_SELLER, res.data.sellerCode)
        this.retrieveSeller()
      } else if (res.status === '400' && res.code === '4001') {
        this.$dialog.alert({
          title: '提示信息',
          message: '未开通微销商户，立即开通'
        }).then(() => {
          this.$router.push({ path: '/seller/apply' })
        })
      }
    },
    async onlineResult () {
      var pd = { userCode: this.upper, taskCode: '1' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/online/result/createInvite', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.returnPage()
      }
    },
    register () {
      this.$router.push({ path: '/seller/register' })
    },
    returnPage () {
      if (this.returnBack === 'N') {
        this.$router.push({ path: '/seller/home' })
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.login-top {
  width: 100%;
}
.form {
  padding: 10px 5px 10px 5px;
}
.title {
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  padding-left: 10px;
  color: #999;
  height: 30px;
  line-height: 30px;
}
.subbtn {
  margin: 20px auto;
}
</style>
